
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import AuthModule from "@/store/auth";
import MemberService from "@/services/members";

@Component({
  name: "NotificationStop",
  components: {
    Header,
    Footer,
  },
})
export default class NotificationStop extends CheckCommonLogin {
  private unsubscribeLoading = false;
  private get me() {
    return AuthModule.me;
  }

  private toMypage() {
    this.$router.push({ name: "MyPage" });
  }
  private async onUnsubscribe() {
    try {
      this.unsubscribeLoading = true;
      if (this.me) {
        await MemberService.update(this.me?.id, { optin_consented_at: null });
        const res = await MemberService.get(this.me?.id);
        AuthModule.changeMe(res.data.data[0]);
        this.unsubscribeLoading = false;
      }
      this.$router.push({ name: "NotificationStopSuccess" });
    } catch (error: any) {
      this.unsubscribeLoading = false;
      throw new Error(error);
    }
  }
}
